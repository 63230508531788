<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img src="@/assets/logo/logo.png" class="img-banner" alt="" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <a href="https://simbangun.bandungkab.go.id/" class="btn btn-primary m-1">Beranda</a> -->
      <!-- <a href="https://webgis.distan2.kabbdg.com/" class="btn btn-primary m-1">Peta Inteaktif</a> -->
      <a :href="fullLaravelLink" class="btn btn-primary m-1">Peta Inteaktif</a>
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-sm-auto">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      roleName: '', // Store the role name here
    };
  },
  mounted() {
    // Retrieve and set the role name when the component is mounted
    const userLogin = localStorage.getItem('user');
    
    if (userLogin) {
      try {
        const user = JSON.parse(userLogin);
        this.roleName = user.role?.name || 'defaultRole'; // Set the role name or a default value
      } catch (error) {
        console.error('Error parsing user data from local storage:', error);
        this.roleName = 'defaultRole'; // Fallback in case of an error
      }
    } else {
      this.roleName = 'defaultRole'; // Fallback if no user data is found
    }
  },
  computed: {
    fullLaravelLink() {
      // Create the full URL using the role name as a parameter
      return `https://webgis.distan2.kabbdg.com/?key1=${encodeURIComponent(this.roleName)}`;
    }
  }
};
</script>

<style scoped>
.img-banner {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .img-banner {
    width: 45% !important;
  }
}
</style>
